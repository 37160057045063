<template>
    <div class="ce-content">

        <div class="row ce-row">

            <!-- First col ------------------------>
            <div class="col-md-12">

                <!-- ce-content-inner ------------------------------>
                <div class="ce-content-inner">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="ce-welcome-user">
                                <h1>Welcome <span>{{ user.name }} </span></h1>
                                <ul>
                                    <li class="ce-admin-user-icon">
                                        <img src="../assets/images/user-icon.png" alt="">
                                        <div class="ce-online"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div v-if="accessDenied" class="alert alert-danger fade show" role="alert">
                                <div><strong>Access Denied!</strong> You don't have permission to access "{{domain}}"</div>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                        </div>
                        <div v-if="!clients.length" class="col-md-12">
                            <div class="nothing-to-display">
                                No Clients to display !!
                            </div>
                        </div>
                        <client-card v-else v-for="client in clients" :name="client.name"
                                      :link="client.redirect" :key="client.name">
                        </client-card>

                    </div>
                </div>
                <!-- ce-content-inner End ------------------------>

            </div>
            <!-- First col End -------------------------------->

        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex';
    import Api from '../includes/Api';
    import ClientCard from '../components/ClientCard';

    export default {
        components: {
            'client-card': ClientCard
        },
        data() {
            return {
                accessDenied: false,
                clients: [],
                domain : '',
            }
        },
        computed: mapState('User',['user']),
        methods: {
            getClients() {
                let root = this;
                Api.get('/client').then((response) => {
                    root.clients = response.data.data
                });
            },
        },
        created() {
            this.getClients();
            let msg = this.$route.params.msg;
            if(msg == 'accessDenied'){
                this.accessDenied = true;
                this.domain = this.$route.params.url;
            }

        }
    }
</script>

<template>
    <div class="col-md-3">
   <section v-if="link">
        <a  :href="link" target="_blank">
            <div class="flip-card">
                <div class="flip-card-inner">
                    <div class="flip-card-front">
                        <h1>{{ name }}</h1>
                    </div>
                    <div class="flip-card-back">
                        <h1>{{ link }}</h1>
                        <p>{{ name }}</p>
                            <button  class="btn ce-btn-link">Go to Website</button>
                    </div>
                </div>
            </div>
        </a>
        </section>
    </div>
</template>
<script>
    export default {
        props: ['name','link','clientId'],
        data() {
            return {
              isAllowed:10
            }
        },
    }

</script>
